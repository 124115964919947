import { Divider } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="bg-[#F5004F] text-white font-bold text-xl text-center mx-2 p-2 md:text-2xl mx-6">
        <h1>Privacy Policy for Shohoz Shadhi</h1>
      </div>
      <div className="mx-4 md:mx-10">
        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          GENERAL INFORMATION
        </h2>
        <p className="text-justify mx-4 text-gray-500 mb-4">
          At Shohoz Shadhi, accessible from our mobile application, the privacy
          of our users is one of our main priorities. This Privacy Policy
          document outlines the types of information we collect and how we use
          and protect it.
        </p>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Shohoz Shadhi is a matrimony application that enables users to find
          and choose their life partners based on preferences and filters within
          the app. By using the app, you agree to the practices outlined in this
          Privacy Policy.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          INFORMATION COLLECTION AND USE
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          We collect several types of information to provide and improve our
          services:
        </p>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          <span className="font-semibold gap-1 text-gray-700 text-lg">
            Personal Information:{" "}
          </span>
          <br />
          <span className="font-semibold text-gray-700">User Registration:</span>{" "}
          We collect personal details such as name, email address, and basic
          background information to help match users with potential partners
          based on their preferences.
          <br />
          <span className="font-semibold text-gray-700">Location Data:</span>{" "}
          Your location is used to filter potential matches and provide
          location-based services within the app. You can choose to share or
          restrict location access through your device settings.
          <br />
          <span className="font-semibold text-gray-700">Photos and Camera Access:</span>{" "}
          We require access to your device's camera to allow you to upload
          profile pictures. We do not store or access these photos beyond your
          app profile usage.
          <br />
          <span className="font-semibold text-gray-700">In-App Notifications:</span>{" "}
          We may send you notifications to keep you updated about new matches,
          messages, or app features.
        </p>

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          SECURITY AND PASSWORDS
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          We take your privacy and security seriously. All passwords are stored
          in a hashed format using industry-standard encryption to protect your
          data from unauthorized access.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          USER RIGHTS AND CONTROLS
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Users can update or delete their profile and account information
          anytime through the app settings. We provide full control over your
          data, ensuring you can manage your preferences at any time.
        </p>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          All personal information collected from users is treated as
          confidential. We do not share, sell, or rent your data to any third
          parties, except as outlined below.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          THIRD-PARTY ACCESS AND SHARING
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          <span className="font-semibold text-gray-700">Service Providers:</span>{" "}
          We may share your information with third-party service providers who
          assist in maintaining and improving our platform. These partners are
          contractually obligated to keep your information secure.
        </p>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          <span className="font-semibold text-gray-700">Legal Requirements:</span>{" "}
          In cases where legal authorities or government requests require us to
          disclose user data, we will comply with such requests while
          maintaining transparency with our users.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          DATA RETENTION
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          We retain user data as long as it is needed to fulfill the purposes
          outlined in this Privacy Policy or as required by law. You can request
          to delete your data at any time.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          SECURITY MEASURES
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          We implement robust security measures to protect the information you
          provide us. However, please note that no method of transmission or
          storage is completely secure. We continuously strive to ensure the
          highest level of security for your data.
        </p>

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          CHAT FEATURE AND ENCRYPTION
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Our chat feature allows users to communicate with their potential
          matches within the app. To ensure privacy and security, all chat
          messages are protected by end-to-end encryption. This means that only
          the sender and receiver can read the messages, and no third parties,
          including Shohoz Shadhi, can access or view the conversation.
        </p>
      

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          COOKIES AND TRACKING TECHNOLOGIES
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Shohoz Shadhi may use cookies and similar tracking technologies to
          enhance your experience on the app, such as remembering your login
          session and personal preferences.
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          CONTACT US
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          If you have any questions about this Privacy Policy, feel free to
          reach out to us at:
        </p>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Name: Shohoz Shadi <br />
          Email: <a href="mailto:shohozshadi@gmail.com" className="text-[#F5004F]">shohozshadi@gmail.com</a>
        </p>

        <Divider sx={{ color: "red" }} className="orange-divider" />

        <h2 className="flex text-xl items-center justify-start text-gray-700 font-bold gap-1 px-2 mx-2 mb-2 mt-2">
          CHANGES TO THIS POLICY
        </h2>

        <p className="text-justify mx-4 text-gray-500 mb-4">
          Shohoz Shadhi reserves the right to update this Privacy Policy from
          time to time. We encourage users to review this page periodically for
          any changes. Continued use of the app implies your acceptance of the
          revised terms.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
